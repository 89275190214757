import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { Loading, Toast } from 'wangsvue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'App',
    setup(__props) {
        return (_ctx, _cache) => {
            const _component_router_view = _resolveComponent("router-view");
            return (_openBlock(), _createElementBlock(_Fragment, null, [
                _createVNode(_unref(Loading)),
                _createVNode(_unref(Toast)),
                _createVNode(_component_router_view)
            ], 64));
        };
    }
});

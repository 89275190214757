import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/crm/customer',
        component: () => import('@/layout/CRMLayout.vue'),
        redirect: { name: 'Customer' },
        children: [
            {
                path: '',
                name: 'Customer',
                component: () => import('@/views/CustomerView.vue'),
            },
            {
                path: 'edit',
                name: 'EditCustomer',
                component: () => import('@/views/EditCustomerView.vue'),
            },
            {
                path: 'kontak-customer',
                name: 'CustomerContact',
                component: () => import('@/views/CustomerContactView.vue'),
            },
            {
                path: 'kontak-customer/buat',
                name: 'CreateCustomerContact',
                component: () => import('@/views/CreateCustomerContactView.vue'),
            },
            {
                path: 'detail',
                name: 'CustomerDetail',
                component: () => import('@/views/CustomerDetailView.vue'),
            },
            {
                path: 'kontak-customer/edit',
                name: 'EditCustomerContact',
                component: () => import('@/views/EditCustomerContactView.vue'),
            },
            {
                path: 'kontak-customer/detail',
                name: 'CustomerContactDetail',
                component: () => import('@/views/CustomerContactDetailView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
